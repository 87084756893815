import React, {useEffect} from 'react';
import './AvailableHomes.scss';
import { useAnalytics } from '../../customHooks/reactGA';

import Appbar from '../../components/Appbar/Appbar';
import Footer from '../../components/Footer/Footer';
import { Box, Typography, Button, Link } from '@mui/material';

import HouseLogoPlain_light from '../../assets/carp_house_plain.png';
import HouseLogoPlain_dark from '../../assets/carp-house-plain-dark.png';

const AvaiableHomes = (props) => {

  const { trackPageView, trackEvent } = useAnalytics();
      
  useEffect(() => {
    trackPageView('/available-homes');
  }, [trackPageView]);

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ height: 'calc(100vh - 64px)', backgroundColor: '#e4e4e4' }}>
        <Box mb={5}>
          {
            props.theme.palette.mode === "dark" ? (
              <img src={HouseLogoPlain_light} height='150px' width='auto' alt='CarpHomes House Logo light' />
            ) : (
              <img src={HouseLogoPlain_dark} height='150px' width='auto' alt='CarpHomes House Logo dark' />
            )
          }
        </Box>
        <Box textAlign='center'>
          <Typography sx={{ fontFamily: 'karla', fontSize: '1.5rem', color: props.theme.palette.mode === "dark" ? props.theme.palette.secondary.light : props.theme.palette.primary.dark }}>We currently do not have any available listings.</Typography>
          <br/>
          <Typography sx={{ color: props.theme.palette.mode === "dark" ? props.theme.palette.common.white : props.theme.palette.primary.main }}>Please check back again soon.</Typography>
        </Box>
      </Box>

      <div className="content-wrapper">
        <Box mb={15}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            mt={15}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.light
                : props.theme.palette.primary.dark
            }
          >
            Supplemental Services
          </Typography>
          <Typography
            lineHeight={2}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            In addition to our core focus of purchasing properties directly, our team offers supplemental services tailored to specific needs:
          </Typography>
          <Box>
            <ul>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Realtor Services - </b>
                  </span> 
                  If you're considering selling your home through the traditional market, our team can assist with listing your property and navigating the sales process. While our primary focus is on purchasing homes, this service is available for homeowners who prefer a traditional approach.
                </Typography>
              </li>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Construction Services for Investors - </b>
                  </span> 
                  We provide expert construction management services exclusively for real estate investors. Whether you're a seasoned investor or new to the market, we can oversee renovation projects to maximize value and streamline the process. This service is ideal for out-of-state investors looking to expand their portfolio in the Indianapolis area without the hassle of managing projects locally.                </Typography>
              </li>            
            </ul>
          </Box>
        </Box>
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: "25px 25px 0 0",
          }}
        >
          <Box className="contact-us-text-container">
            <Typography variant="h4" fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.dark
                  : props.theme.palette.primary.main
              } textAlign={'center'}
            >
              Sell Your Indiana Home Fast with CarpHomes
            </Typography>
            <Typography lineHeight={2.5} mt={2}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              } textAlign={'center'}
            >
              We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
            </Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.primary.main,
                }}
              >
                <Link className="link-item" to="/contact">
                  <Typography color={props.theme.palette.common.white}>
                    Reach Out
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>
      <Footer />
    </div>
  )
}

export default AvaiableHomes;