import ReactGA from 'react-ga4';

export const useAnalytics = () => {

    // track page views
    const trackPageView = (pageName) => {
        ReactGA.send({ hitType: "pageview", page: pageName });
    };

    // track events
    const trackEvent = (category, action, label, value = null) => {
        ReactGA.event({
          category,
          action,
          label,
          value,
        });
    };

    // track scroll depth
    const trackScrollDepth = (sectionName) => {
        ReactGA.event({
          category: "Engagement",
          action: "Scroll Depth",
          label: sectionName,
        });
    };

    // track time on section
    const trackTimeOnSection = (sectionName, timeSpent) => {
        ReactGA.event({
          category: "Engagement",
          action: "Time on Section",
          label: sectionName,
          value: timeSpent,
        });
    };

    return { trackPageView, trackEvent, trackScrollDepth, trackTimeOnSection };

}