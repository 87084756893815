import React, { useEffect } from 'react';
import './FAQs.scss';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../../customHooks/reactGA';

import Appbar from '../../components/Appbar/Appbar';
import Footer from '../../components/Footer/Footer';

import { Typography, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

const FAQs = (props) => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('/frequently-asked-questions');
  }, [trackPageView]);

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              Frequently Asked Questions
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              Explore our Frequently Asked Questions (FAQ's) to find answers to common inquiries about CarpHomes. If you can't find what you're looking for, reach out to our team for further assistance. We're here to ensure you have the information you need for a seamless experience with us.
            </p>
          </div>
        </div>
      </section>
      <div className='content-wrapper'>
        <Box padding={5}>
          <Box mt={20} mb={5}>
            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  Are you going to list my home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  No. CarpHomes can offer that service if you wish, but
                  that is not our goal. We want to buy your home directly.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  What extra fees or costs are involved?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  None! You do not have to pay any realty
                  commissions, any closing costs, or any administrative/service fees when selling us your
                  home.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  What if I do not like your cash offer?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  That is perfectly okay. We believe that a transaction
                  must always be a good fit for both parties - it is not a one-way street. We would love the
                  opportunity to work with you, but we leave that decision up to you!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  Why should I trust CarpHomes?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  Travis Carpenter, our CEO and owner, has been deeply involved in the real estate investment industry since 2018, specializing in the Indiana market. With extensive experience in buying, selling, and renovating hundreds of homes, Travis and our team have developed unmatched expertise in home evaluations and seamless transaction execution. As a licensed Realtor and General Contractor, Travis ensures that every transaction is handled with the highest level of professionalism, knowledge, and precision, providing you with confidence and peace of mind.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  My story is complicated - can you still help me?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  Absolutely. Foreclosures, trusts, multiple
                  owners, probate…. You name it, we can handle it!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box mb={15}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            mt={15}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.light
                : props.theme.palette.primary.dark
            }
          >
            Supplemental Services
          </Typography>
          <Typography
            lineHeight={2}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            In addition to our core focus of purchasing properties directly, our team offers supplemental services tailored to specific needs:
          </Typography>
          <Box>
            <ul>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Realtor Services - </b>
                  </span>
                  If you're considering selling your home through the traditional market, our team can assist with listing your property and navigating the sales process. While our primary focus is on purchasing homes, this service is available for homeowners who prefer a traditional approach.
                </Typography>
              </li>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Construction Services for Investors - </b>
                  </span>
                  We provide expert construction management services exclusively for real estate investors. Whether you're a seasoned investor or new to the market, we can oversee renovation projects to maximize value and streamline the process. This service is ideal for out-of-state investors looking to expand their portfolio in the Indianapolis area without the hassle of managing projects locally.                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: "25px 25px 0 0",
          }}
        >
          <Box className="contact-us-text-container">
            <Typography variant="h4" fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.dark
                  : props.theme.palette.primary.main
              } textAlign={'center'}
            >
              Sell Your Indiana Home Fast with CarpHomes
            </Typography>
            <Typography lineHeight={2.5} mt={2}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              } textAlign={'center'}
            >
              We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
            </Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.primary.main,
                }}
              >
                <Link className="link-item" to="/contact">
                  <Typography color={props.theme.palette.common.white}>
                    Reach Out
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>
      <Footer />
    </div>
  )
}

export default FAQs;