import React from "react";
import "./Appbar.scss";

import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
// import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Stack from '@mui/material/Stack';

import navLogo from "../../assets/carp_house_plain.png";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#1a3553" : "#c76119",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const Appbar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div>
      <AppBar
        position="static"
        sx={{ backgroundColor: props.theme.palette.primary.main, backgroundImage: 'none' }}
      >
        <Container maxWidth="none">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img className="navLogo" src={navLogo} alt="CarpHomes Logo" />
            </Box>
            <Typography
              // variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                color: props.theme.palette.common.white,
                letterSpacing: ".25rem",
                textDecoration: "none",
              }}
            >
              <span>CARP</span>
              <span
                style={{
                  color: props.theme.palette.warning.main,
                  fontFamily: "latoBold",
                }}
              >
                HOMES
              </span>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {/* MOBILE PAGES */}
                <MenuItem>
                  <Link className="link-item" to="/sell-my-home" style={{color: '#1a2038'}}>
                    Sell My Home
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="link-item" to="/about" style={{color: '#1a2038'}}>
                    About
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="link-item" to="/available-homes" style={{color: '#1a2038'}}>
                    Available Homes
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="link-item" to="/frequently-asked-questions" style={{color: '#1a2038'}}>
                    FAQ's
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="link-item" to="/contact" style={{color: '#1a2038'}}>
                    Contact
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <img className="navLogo" src={navLogo} alt="CarpHomes Logo" />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                color: props.theme.palette.common.white,
                letterSpacing: ".25rem",
                textDecoration: "none",
              }}
            >
              <span className="appbar-title-span">CARP</span>
              <span className="appbar-title-span"
                style={{
                  color: props.theme.palette.warning.main,
                  fontFamily: "latoBold",
                }}
              >
                HOMES
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <MenuItem>
                <Link className="link-item" to="/sell-my-home">
                  <Typography
                    sx={[
                      { color: props.theme.palette.common.white },
                      {
                        ":hover": {
                          color: props.theme.palette.warning.main,
                        },
                      },
                    ]}
                  >
                    Sell My Home
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-item" to="/about">
                  <Typography
                    sx={[
                      { color: props.theme.palette.common.white },
                      {
                        ":hover": {
                          color: props.theme.palette.warning.main,
                        },
                      },
                    ]}
                  >
                    About
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-item" to="/available-homes">
                  <Typography
                    sx={[
                      { color: props.theme.palette.common.white },
                      {
                        ":hover": {
                          color: props.theme.palette.warning.main,
                        },
                      },
                    ]}
                  >
                    Available Homes
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-item" to="/frequently-asked-questions">
                  <Typography
                    sx={[
                      { color: props.theme.palette.common.white },
                      {
                        ":hover": {
                          color: props.theme.palette.warning.main,
                        },
                      },
                    ]}
                  >
                    FAQ's
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-item" to="/contact">
                  <Typography
                    sx={[
                      { color: props.theme.palette.common.white },
                      {
                        ":hover": {
                          color: props.theme.palette.warning.main,
                        },
                      },
                    ]}
                  >
                    Contact
                  </Typography>
                </Link>
              </MenuItem>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <MaterialUISwitch
                      sx={{ m: 1 }}
                      checked={props.theme.palette.mode === "dark" ? true : false}
                      onClick={() => props.colorMode.toggleColorMode()}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Appbar;
