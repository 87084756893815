import React from 'react';
import './Form.scss';
import { useAnalytics } from "../../customHooks/reactGA";

import emailjs from "@emailjs/browser";
import { Box, Typography, TextField, Button } from "@mui/material";

const Form = (props) => {
    const { trackEvent } = useAnalytics();

    const form = React.useRef();

    const [customerName, setCustomerName] = React.useState('');
    const [ownerName, setOwnerName] = React.useState('');
    const [address, setAddress] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [details, setDetails] = React.useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current);

        emailjs
            .sendForm(
                "service_6zy89mx",
                "template_v7goata",
                form.current,
                "9dDfLbFoZurbCagy1"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    // let po_btn = document.getElementById("po_btn");
                    // po_btn.click();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                ref={form}
                onSubmit={(event) => {
                    sendEmail(event);
                    setCustomerName("");
                    setOwnerName("")
                    setAddress("")
                    setEmail("")
                    setPhone("");
                    setDetails("");
                }}
            >
                <Box 
                    display={"flex"}
                    flexDirection={"column"}
                    alignContent={"flex-start"}
                >
                    <Box
                        className="form-row"
                        display={'flex'}
                    >
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Full Name
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                id="customer_name"
                                name="customer_name"
                                label="First and last name"
                                variant="outlined"
                                value={customerName}
                                onChange={(event) => setCustomerName(event.target.value)}
                            />
                        </Box>
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Name of Property Owner
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                id="owner_name"
                                name="owner_name"
                                label="If different than your own name"
                                variant="outlined"
                                value={ownerName}
                                onChange={(event) => setOwnerName(event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box
                        className="form-row"
                        display={'flex'}
                    >
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Property Address
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                id="property_address"
                                name="property_address"
                                label="e.g., 123 Main St, Springfield, IN 46140"
                                variant="outlined"
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                            />
                        </Box>
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Email Address
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                id="email_address"
                                name="email_address"
                                label="Email address"
                                variant="outlined"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Box>
                    </Box>

                    <Box
                        className="form-row"
                        display={'flex'}
                    >
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Phone Number
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                id="phone"
                                name="phone"
                                label="(000)-000-0000"
                                variant="outlined"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                            />
                        </Box>
                        <Box
                            padding={1}
                            flex={1}
                        >
                            <label>
                                <Typography 
                                    mb={'0.25em'}
                                    color={
                                        props.theme.palette.mode === "dark"
                                          ? props.theme.palette.common.white
                                          : props.theme.palette.primary.main
                                      }
                                >
                                    Why are you interested in selling your house to CarpHomes?
                                </Typography>
                            </label>
                            <TextField
                                fullWidth
                                multiline
                                id="details"
                                name="details"
                                label="e.g., relocating, downsizing, financial reasons"
                                variant="outlined"
                                value={details}
                                onChange={(event) => setDetails(event.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <br />
                <br />
                <Box className="sbm-container" ml={1}>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="warning"
                        onClick={() => trackEvent('Form Submission', 'Submit', 'No Obligation Cash Offer Form')}
                    >
                        Submit and Get an Offer
                    </Button>
                </Box>
            </Box>

        </div>
    )
}

export default Form;