import React, {useEffect} from "react";
import "./PrivacyPolicy.scss";
import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import { Box, Typography } from "@mui/material";
import { useAnalytics } from "../../customHooks/reactGA";

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

const PrivacyPolicy = (props) => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('/privacy-policy');
  }, [trackPageView]);

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              Privacy Policy
            </h1>
          </div>
        </div>
      </section>
      <div className="content-wrapper">
        <Box padding={5} mt={15} mb={15}>
          <Typography
            variant="h6"
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Privacy Policy</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Effective Date:</b> 07/08/2024
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Introduction</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            Carp Homes ("we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you visit our website,
            <a href="https://www.carphomes.net" style={{ marginLeft: '2.75px' }}>https://www.carphomes.net</a>.
            Please read this privacy policy carefully. If you do not agree with
            the terms of this privacy policy, please do not access the site.
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Information We Collect</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We may collect information about you in a variety of ways. The
            information we may collect on the site includes:
          </Typography>
          <ul>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                <b>Personal Data: </b>Personally identifiable information, such
                as your name, shipping address, email address, and telephone
                number, that you voluntarily give to us when you register with
                the site or when you choose to participate in various activities
                related to the site.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                <b>Derivative Data: </b>Information our servers automatically
                collect when you access the site, such as your IP address, your
                browser type, your operating system, your access times, and the
                pages you have viewed directly before and after accessing the
                site.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                <b>Financial Data: </b>Financial information, such as data
                related to your payment method (e.g., valid credit card number,
                card brand, expiration date) that we may collect when you
                purchase, order, return, exchange, or request information about
                our services from the site.
              </Typography>
            </li>
          </ul>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Use of Your Information</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We may use information collected about you via the site to:
          </Typography>
          <ul>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Create and manage your account.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Process your transactions and send related information.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Email you regarding your account or order.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Improve and personalize your experience on our site.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Monitor and analyze usage and trends to improve your experience
                with the site.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Respond to your comments, questions, and requests, and provide
                customer service.
              </Typography>
            </li>
          </ul>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Disclosure of Your Information</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We may disclose information we have collected about you in certain
            situations. Your information may be made available as follows:
          </Typography>
          <ul>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                <b>By Law or to Protect Rights: </b> If we believe the release
                of information about you is necessary to respond to legal
                process, to investigate or remedy potential violations of our
                policies, or to protect the rights, property, and safety of
                others.
              </Typography>
            </li>
            <li>
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                <b>Business Transfers: </b>We may disclose your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </Typography>
            </li>
          </ul>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Security of Your Information</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are completely secure and no method of data transmission over the
            Internet or wireless network can be guaranteed against interception
            or other types of misuse.
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Policy for Children</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We do not knowingly solicit information from or market to children
            under the age of 13. If we learn that we have collected personal
            information from a child under age 13 without verification of
            parental consent, we will delete that information as quickly as
            possible.
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Options Regarding Your Information</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            You may review, change, or terminate your account at any time. Based
            on the applicable laws of your country, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it.
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Changes to This Privacy Policy</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We may update this Privacy Policy from time to time in order to
            reflect changes to our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any changes by posting the
            new Privacy Policy on this page.
          </Typography>
          <br />
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            <b>Contact Us</b>
          </Typography>
          <Typography
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            If you have questions or comments about this Privacy Policy, please
            contact us by using the form on the{" "}
            <a
              href="https://www.carphomes.net/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact Page
            </a>
            .
          </Typography>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
