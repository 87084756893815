import React, { useEffect } from "react";
import "./About.scss";
import { Link } from "react-router-dom";
import { useAnalytics } from "../../customHooks/reactGA";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import { Typography, Box, Button } from "@mui/material";

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';
import TravisHeadshot from "../../assets/Travis_headshot.png";
import JoyHeadshot from "../../assets/Joy_headshot.png";

const About = (props) => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('/about');
  }, [trackPageView]);

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />

      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              Local, Trusted, and Ready to Help.
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              At CarpHomes, we’re more than just real estate investors—we’re your neighbors, committed to providing personalized solutions and creating positive change in the Indiana communities we call home.
            </p>
          </div>
        </div>
      </section>

      <div className="content-wrapper">
        <Box padding={5} mt={10} mb={10}>
          <Typography
            sx={{
              fontFamily: "karla",
              color:
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.main
                  : props.theme.palette.primary.main,
              fontSize: '2.25rem'
            }}
          >
            A Family-Owned Business Dedicated to Supporting Our Community
          </Typography>
          <br />
          <Typography lineHeight={2.5} fontSize={20}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            As a family-owned, locally-based business, CarpHomes is more than just a real estate investor—we’re your neighbors. We’re dedicated to helping homeowners in our community by transforming houses into better housing opportunities for Hoosiers. We understand the emotional and practical challenges of selling a home and work hard to make the process as simple and transparent as possible.
          </Typography>
          <br />
          <br />
          <Typography
            sx={{
              fontFamily: "karla",
              color:
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.main
                  : props.theme.palette.primary.main,
              fontSize: '2rem'
            }}
          >
            What Sets us Apart?
          </Typography>
          <Typography lineHeight={2.5} fontSize={20}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            We buy houses in any condition. Whether your property is move-in ready or needs significant repairs, we’ll make a fair cash offer. Plus, with no realtor commissions or closing costs, selling to us is hassle-free and convenient.
          </Typography>
          <br />
          <Typography lineHeight={2.5} fontSize={20}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            When CarpHomes makes you an offer, we stand by it! Unlike others who might leave you hanging at the closing table, we’re committed to seeing the process through. We can even waive inspections, so you never have to worry about surprises or last-minute changes. At CarpHomes, we’re just as eager as you are to make it to closing—quickly and easily.
          </Typography>
        </Box>
      </div>

      <Box
        bgcolor={
          props.theme.palette.mode === "dark"
            ? props.theme.palette.primary.dark
            : '#e4e4e4'
        }
        pt={5}
        pb={10}
      >
        <div className="content-wrapper">

          <div id="tm-1" className="team-member-container">
            <Box padding={2.5}>
              <Box
                bgcolor={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.main
                    : props.theme.palette.warning.main
                }
                height={"300px"}
                width={"300px"}
                borderRadius={50}
                overflow={"hidden"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <img
                  src={TravisHeadshot}
                  height={"275px"}
                  width={"auto"}
                  style={{
                    marginLeft: "0.75em",
                  }}
                  alt="Travis Carpenter Headshot"
                />
              </Box>
              <Typography
                textAlign={"center"}
                fontFamily={"latoBold"}
                mt={2.5}
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.secondary.main
                      : props.theme.palette.warning.main,
                }}
              >
                Travis Carpenter, CEO
              </Typography>
            </Box>

            <Box padding={2.5}>
              <Typography lineHeight={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                I am an Indianapolis native, proud alumni of Center Grove High
                School, and a graduate of Indiana Wesleyan University in Marion,
                IN, where I earned my bachelor's degree. My journey in real estate
                began in 2018, and since then, I have garnered extensive
                experience in various facets of the industry. From selling homes
                both on and off-market to acquiring properties, managing
                renovation projects, and building rental portfolios, I have honed
                my skills in navigating complex legal transactions and providing
                valuable consulting services.
              </Typography>
              <br />
              <Typography lineHeight={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                As a dedicated professional, I prioritize exceptional customer
                service and practical problem-solving to ensure client
                satisfaction. My ability to make confident decisions, coupled with
                a steadfast commitment to professionalism, has earned me the trust
                of clients and colleagues alike.
              </Typography>
              <br />
              <Typography lineHeight={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Outside of work, I am a fervent people-person who relishes the
                opportunity to forge meaningful connections. Alongside my wife,
                Deanna, I reside in the north suburbs, where we eagerly anticipate
                the arrival of our first child this summer. Our household is
                completed by our beloved dogs, Frodo and Lexi.
              </Typography>
            </Box>
          </div>

          <div id="tm-2" className="team-member-container">
            <Box padding={2.5}>
              <Typography lineHeight={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Joy serves as an Administrative Assistant at CarpHomes, bringing a strong focus on exceptional customer service and administrative expertise. With a passion for helping others and a natural talent for communication, Joy thrives on connecting with customers over the phone, ensuring their questions are answered and their needs are met with efficiency and care.
              </Typography>
              <br />
              <Typography lineHeight={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                In addition to their customer service strengths, Joy excels in managing administrative tasks, keeping operations running smoothly, and supporting the team with attention to detail and a positive attitude. Their dedication to providing outstanding service makes them an invaluable part of the CarpHomes team.              </Typography>
            </Box>
            <Box padding={2.5}>
              <Box
                bgcolor={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.main
                    : props.theme.palette.warning.main
                }
                height={"300px"}
                width={"300px"}
                borderRadius={50}
                overflow={"hidden"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <img
                  src={JoyHeadshot}
                  height={"275px"}
                  width={"auto"}
                  style={{
                    marginLeft: "-1em",
                  }}
                  alt="Joy Navarro Headshot"
                />
              </Box>

              <Typography
                textAlign={"center"}
                fontFamily={"latoBold"}
                mt={2.5}
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.secondary.main
                      : props.theme.palette.warning.main,
                }}
              >
                Joy Navarro, Executive Assistant
              </Typography>
            </Box>
          </div>
        </div>
      </Box>

      <section
        className="sell-my-home"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.secondary.main
              : props.theme.palette.primary.main,
          padding: '10em 5em 10em 5em',
          textAlign: 'center'
        }}
      >

        <Box>
          <Typography
            sx={{
              color:
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.main
                  : props.theme.palette.common.white,
              fontSize: "2rem",
              fontFamily: "karla",
            }}
          >
            Ready to sell? Let us make you an offer today.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "2.5em" }}>
          <Button
            variant="contained"
            color="warning"
            sx={{ padding: "15px 25px" }}
            onClick={() => trackEvent('CTA', 'Click', 'Get Your Cash Offer Now')}
          >
            <Link className="link-item" to="/sell-my-home">
              <Typography
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.common.white,
                  fontFamily: "latoBold",
                  fontSize: "1.15rem",
                }}
              >
                Get your Cash Offer Now
              </Typography>
            </Link>
          </Button>
        </Box>
      </section>

      <div className="content-wrapper">
        <Box mb={15}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            mt={15}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.light
                : props.theme.palette.primary.dark
            }
          >
            Supplemental Services
          </Typography>
          <Typography
            lineHeight={2}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            In addition to our core focus of purchasing properties directly, our team offers supplemental services tailored to specific needs:
          </Typography>
          <Box>
            <ul>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Realtor Services - </b>
                  </span>
                  If you're considering selling your home through the traditional market, our team can assist with listing your property and navigating the sales process. While our primary focus is on purchasing homes, this service is available for homeowners who prefer a traditional approach.
                </Typography>
              </li>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Construction Services for Investors - </b>
                  </span>
                  We provide expert construction management services exclusively for real estate investors. Whether you're a seasoned investor or new to the market, we can oversee renovation projects to maximize value and streamline the process. This service is ideal for out-of-state investors looking to expand their portfolio in the Indianapolis area without the hassle of managing projects locally.                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: "25px 25px 0 0",
          }}
        >
          <Box className="contact-us-text-container">
            <Typography variant="h4" fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.dark
                  : props.theme.palette.primary.main
              } textAlign={'center'}
            >
              Sell Your Indiana Home Fast with CarpHomes
            </Typography>
            <Typography lineHeight={2.5} mt={2}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              } textAlign={'center'}
            >
              We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
            </Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.primary.main,
                }}
              >
                <Link className="link-item" to="/contact">
                  <Typography color={props.theme.palette.common.white}>
                    Reach Out
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>

      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default About;
