import React, { useEffect } from "react";
import "./Splash.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import Form from "../../components/Form/Form";
import { useAnalytics } from "../../customHooks/reactGA";

import { Box, Button, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TravisHeadshot from '../../assets/Travis_headshot.png';
import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';
import iconWrench from '../../assets/wrench.png';
import iconWrenchDark from '../../assets/wrench_dark.png';
import iconDeadline from '../../assets/deadline.png';
import iconDeadlineDark from '../../assets/deadline_dark.png';
import iconMoney from '../../assets/money.png';
import iconMoneyDark from '../../assets/money_dark.png';
import iconTrust from '../../assets/relationship.png';
import iconTrustDark from '../../assets/relationship_dark.png';

// import Commercial from '../../assets/2024_carp_homes.mp4';
// import ReactPlayer from 'react-player';

const Splash = (props) => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('/');
  }, [trackPageView]);

  return (
    <div>
      <Appbar
        colorMode={props.colorMode}
        theme={props.theme}
        mode={props.mode}
      />

      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
              }}
            >
              Sell your House
              <span
                style={{
                  color: props.theme.palette.warning.main,
                  marginLeft: '10px'
                }}
              >
                Fast
              </span>
              ,
              <br />
              for
              <span
                style={{
                  color: props.theme.palette.warning.main,
                  marginLeft: '10px'
                }}
              >
                Cash
              </span>
              ,
              <br /> with
              <span
                style={{
                  color: props.theme.palette.warning.main,
                  marginLeft: '10px'
                }}
              >
                No Hassle
              </span>
              .
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              CarpHomes buys homes directly from homeowners in the Indianapolis area, offering speed, cash, and discretion. No commissions, no closing costs, no headaches.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="content-wrapper">
          <section className="welcome-container">
            <Box sx={{ width: "100%", margin: "0 auto" }}>
              <Typography className="welcome-h1 heading"
                sx={{
                  fontFamily: "karla",
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main,
                  marginBottom: '-0.15em',
                }}
              >
                Sell Your Indiana Home Fast and Hassle-Free with CarpHomes
              </Typography>
              <br />
              <Typography className="welcome-h2 heading"
                sx={{
                  fontSize: "1.65rem",
                  lineHeight: "2.25rem",
                  color: props.theme.palette.warning.main,
                  width: '75%'
                }}
              >
                At CarpHomes, we make selling your property in central Indiana as easy as possible.
              </Typography>
              <br />
              <br />
              <Box className='wc-adj'
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: "2.25rem",
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main,
                    }}
                  >
                    As a local business, born and raised right here in Indiana, we understand the community and the unique challenges homeowners face. Whether your home is move-in ready or needs significant repairs, we are ready to buy it.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: "2.25rem",
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main,
                    }}
                  >
                    We specialize in offering cash purchases and can close quickly—often in as little as 7 days. Best of all, you’ll pay no realtor commissions or closing costs when selling to us.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: "2.25rem",
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main,
                    }}
                  >
                    We also provide the privacy and flexibility that comes with selling off-market. No public listings, no open houses, and no lengthy negotiations. Because we’re local, you can count on a personal, one-on-one experience. A member of our team will meet with you in person, understand your needs, and guide you through every step of the process.
                  </Typography>
                </Box>
                <Box padding={2.5} mt={5}>
                  <Box
                    bgcolor={
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.secondary.main
                        : props.theme.palette.warning.main
                    }
                    height={"300px"}
                    width={"300px"}
                    borderRadius={50}
                    overflow={"hidden"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"end"}
                  >
                    <img
                      src={TravisHeadshot}
                      height={"275px"}
                      width={"auto"}
                      style={{
                        marginLeft: "0.75em",
                      }}
                      alt="Travis Carpenter Headshot"
                    />
                  </Box>
                  <Typography
                    textAlign={"center"}
                    fontFamily={"latoBold"}
                    mt={2.5}
                    sx={{
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.warning.main,
                    }}
                  >
                    Travis Carpenter, CEO
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={5}>
              {/* <ReactPlayer url={Commercial} controls="true" width="100%" /> */}
            </Box>
            <Box sx={{ marginTop: "2.5em" }}>
              <Button
                variant="outlined"
                color="warning"
                sx={{ padding: "15px 25px" }}
              >
                <Link className="link-item" to="/about">
                  <Typography
                    sx={{
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main,
                    }}
                  >
                    About Us
                  </Typography>
                </Link>
              </Button>
            </Box>
          </section>
        </div>
      </section>

      <section
        className="offerings-container"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          padding: '10em 5em 10em 5em',
        }}
      >
        <Box mb={10}>
          <Typography
            textAlign={"center"}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.main
            }
            fontSize="2.25rem"
          >
            What We Offer
          </Typography>
          <Typography
            textAlign={"center"}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.main
            }
            fontSize="1.5rem"
          >
            Key Benefits of Selling to CarpHomes
          </Typography>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={7.5}
            textAlign={'center'}
          >
            <Box>
              <img className="feature-ico adj-deadline" src={
                props.theme.palette.mode === "dark"
                  ? iconDeadline
                  : iconDeadlineDark
              }
              />
            </Box>
            <Box>
              <Typography
                fontSize={'1.5rem'}
                fontWeight={600}
                color={props.theme.palette.warning.main}
              >
                Fast Closings
              </Typography>
              <Typography
                fontSize={'1.25rem'}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main
                }
              >Sell your house in as little as 7 days.</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={7.5}
            textAlign={'center'}
          >
            <Box>
              <img className="feature-ico" src={
                props.theme.palette.mode === "dark"
                  ? iconWrench
                  : iconWrenchDark
              }
              />
            </Box>
            <Box>
              <Typography
                fontSize={'1.5rem'}
                fontWeight={600}
                color={props.theme.palette.warning.main}
              >
                No Repairs Needed
              </Typography>
              <Typography
                fontSize={'1.25rem'}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main
                }
              >We buy houses as-is, no matter the condition.</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={7.5}
            textAlign={'center'}
          >
            <Box mb={2}>
              <img className="feature-ico adj-money" src={
                props.theme.palette.mode === "dark"
                  ? iconMoney
                  : iconMoneyDark
              }
              />
            </Box>
            <Box >
              <Typography
                fontSize={'1.5rem'}
                fontWeight={600}
                color={props.theme.palette.warning.main}
              >
                Cash Offers
              </Typography>
              <Typography
                fontSize={'1.25rem'}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main
                }
              >Avoid financing delays. Get paid in cash at closing.</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            mb={7.5}
          >
            <Box>
              <img className="feature-ico" src={
                props.theme.palette.mode === "dark"
                  ? iconTrust
                  : iconTrustDark
              }
              />
            </Box>
            <Box>
              <Typography
                fontSize={'1.5rem'}
                fontWeight={600}
                color={props.theme.palette.warning.main}
              >
                Local and Trustworthy
              </Typography>
              <Typography
                fontSize={'1.25rem'}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main
                }
              >As a local Indy business, we offer in-person service and partner with reputable title companies.</Typography>
            </Box>
          </Box>
        </Box>
      </section>

      <section
        className="promise-container"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.main
              : '#fff',
          padding: '10em 5em 10em 5em',
        }}
      >
        <Box>
          <Typography
            textAlign={"center"}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.main
            }
            fontSize="2.25rem"
          >
            Our Promise
          </Typography>
          <Typography
            textAlign={"center"}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.main
            }
            fontSize="1.5rem"
          >
            Why CarpHomes Stands Out
          </Typography>
        </Box>

        <div className='content-wrapper'>
          <Box mt={10} mb={10}>
            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  We're Invested in Our Community
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  Most homes we purchase are renovated and restored to improve the neighborhood.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  Personalized Customer Service
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  We meet you face-to-face, answer your questions, and guide you every step of the way.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  No Hidden Fees
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  No commissions or closing costs - what we offer is what you'll receive.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.dark
                  : props.theme.palette.grey.A100
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography fontFamily={'latoBold'}
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.main
                  }}
                >
                  Discretion and Respect
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }}
                >
                  We value your privacy and handle every transaction with integrity.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      </section>

      <section
        className="sell-my-home"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.secondary.main
              : props.theme.palette.primary.main,
          padding: '10em 5em 10em 5em',
          textAlign: 'center'
        }}
      >

        <Box>
          <Typography
            sx={{
              color:
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.main
                  : props.theme.palette.common.white,
              fontSize: "2rem",
              fontFamily: "karla",
            }}
          >
            Ready to sell? Let us make you an offer today.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "2.5em" }}>
          <Button
            variant="contained"
            color="warning"
            sx={{ padding: "15px 25px" }}
            onClick={() => trackEvent('CTA', 'Click', 'Get Your Cash Offer Now')}
          >
            <Link className="link-item" to="/sell-my-home">
              <Typography
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.common.white,
                  fontFamily: "latoBold",
                  fontSize: "1.15rem",
                }}
              >
                Get your Cash Offer Now
              </Typography>
            </Link>
          </Button>
        </Box>
      </section>

      <div className="content-wrapper">
        <section className="contact-form">
          <Box
            mt={15}
            mb={10}
          >
            <Typography
              sx={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
                fontSize: "2rem",
                fontFamily: "karla",
                marginBottom: '0.5em',
                textAlign: 'center'
              }}
            >
              Get Your Free, No-Obligation Cash Offer Today
            </Typography>
            <Form theme={props.theme} />
          </Box>
          <Box
            mt={15}
            bgcolor={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.primary.light
                : props.theme.palette.secondary.main
            }
            sx={{
              borderRadius: "25px 25px 0 0",
            }}
          >
            <Box className="contact-us-text-container">
              <Typography
                variant="h4"
                fontFamily={"karla"}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main
                }
                textAlign={'center'}
              >
                Sell Your Indiana Home Fast with CarpHomes
              </Typography>
              <Typography
                lineHeight={2.5}
                mt={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
                textAlign={'center'}
              >
                We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
              </Typography>

              <Box display="flex" justifyContent="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.warning.main
                        : props.theme.palette.primary.main,
                  }}
                  onClick={() => trackEvent('CTA', 'Click', 'Reach Out')}
                >
                  <Link className="link-item" to="/contact">
                    <Typography color={props.theme.palette.common.white}>
                      Reach Out
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Box>
          </Box>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Splash;
